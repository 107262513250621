import { default as icons_45pageosOdU3wp4YMeta } from "/var/www/nature/nature-website/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as indexB7IcFhr4ITMeta } from "/var/www/nature/nature-website/pages/index.vue?macro=true";
import { default as privacyCISiXqMZSUMeta } from "/var/www/nature/nature-website/pages/privacy.vue?macro=true";
import { default as termsiVtqU1Lc9dMeta } from "/var/www/nature/nature-website/pages/terms.vue?macro=true";
import { default as testF0cfRpGbHqMeta } from "/var/www/nature/nature-website/pages/test.vue?macro=true";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/var/www/nature/nature-website/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/nature/nature-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/var/www/nature/nature-website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/var/www/nature/nature-website/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/var/www/nature/nature-website/pages/test.vue").then(m => m.default || m)
  }
]