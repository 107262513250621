<template>
  <div>
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>

    <client-only>
      <Toast />
    </client-only>

    <div id="app-teleport"></div>
  </div>
</template>

<script lang="ts" setup>
await useHome();

useHead({
  htmlAttrs: () => ({
    dir: "rtl",
    lang: "ar",
    title: "Baklava",
  }),
});
</script>
