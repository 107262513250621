export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const token = useCookie("token");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface ApiResponse<T = any> {
    data: T;
  }

  type HeadersType = {
    Authorization?: string;
  };

  // Instance
  const $api = $fetch.create<ApiResponse>({
    baseURL: config?.public?.apiBase,
    onRequest({options}) {
      // Add Authorization header
      options.headers = options.headers || {};
      if (token.value) {
        (options.headers as HeadersType).Authorization = token.value + "";
      }
    },
    onResponseError({response}) {
      return Promise.reject(response);
    },
  });

  return {
    provide: {
      api: $api,
    },
  };
});
