import type {UseFetchOptions} from "nuxt/app";

// Define the desired shape for the response data
interface ApiResponse<T> {
  data: Ref<T>;
  paginator: T;
}

export function useAPI<T>(
  url: string | (() => string),
  options?: UseFetchOptions<ApiResponse<T>>
) {
  return useFetch(url, {
    ...options,
    $fetch: useNuxtApp().$api,
  });
}
