import {get} from "lodash-es";

// Use static
export const useStatic = (path: string = "") => {
  const homePayload = useState("homePayload", () => {});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setValue = (value: any) => {
    homePayload.value = value;
  };

  // Map current value
  const currentValue = computed(() => {
    return path ? get(homePayload?.value, path) : homePayload?.value;
  });

  return {
    value: currentValue,
    setValue,
  };
};

// Use Home Hooke
export const useHome = async () => {
  const nuxtApp = useNuxtApp();

  const {setValue} = useStatic();

  const {data} = await useAPI("/texts", {
    key: "home",
    getCachedData: (key: string) => {
      return nuxtApp?.payload?.data[key] || nuxtApp?.static?.data[key];
    },
  });

  setValue(data?.value?.data);
};
